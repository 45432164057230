import React from "react"
import Header from "./src/components/header"
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

export const wrapPageElement = ({ element, props }) => {
  return (
    <> 
      <Header />
      {element}
    </>
  )
}

export const shouldUpdateScroll = () => false
