import { gsap } from "gsap"

export const openPanel = (panel) => {
  document.querySelector('.panel[data-panel='+panel+']').classList.add('active');
  document.body.classList.add('panel-open');
}

export const closePanel = () => {
  document.querySelector('.panel.active').classList.remove('active');
  document.body.classList.remove('panel-open');
}

export const menuToggle = () => {
  if (document.body.classList.contains("menu-open")) {
    menuClose();
  } else {
    menuOpen();
  }
}

export const menuOpen = () => {
  document.body.classList.add("menu-open")
  document.querySelector(".menu-button").innerHTML = "Close"
  document.querySelector(".header--fixed .menu-button").innerHTML = "Close"
} 

export const menuClose = () => {
  document.body.classList.remove("menu-open")
  document.querySelector(".menu-button").innerHTML = "Menu"
  document.querySelector(".header--fixed .menu-button").innerHTML = "Menu"
} 

export const pageTransition = () => {
  if(document.body.classList.contains('menu-open')) {
    document.body.classList.add('locked');
    menuClose();
    gsap.to('.overlay--menu',{y:'-100vh',duration: 1, ease:'Power3.easeOut', delay:.5});
    gsap.fromTo("main", {y:'100vh', opacity:0}, { y: 0, opacity:1, ease:'Power3.easeOut', duration:1, delay:.5, 
      onComplete: function () {
        document.body.classList.remove("locked"); 
        document.querySelector('.overlay--menu').style.transform='';
        document.querySelector('main').removeAttribute('style');
      }})
  }
}