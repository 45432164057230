import React, { useEffect } from "react"
import {closePanel, menuToggle, openPanel} from '../utils/panelActions'
import { Link } from "gatsby"

const Header = ({ siteTitle, enter, exit, entering, leaving }) => {
  
  useEffect(() => {
    setInterval(function () {
      if(window.pageYOffset < 600) {
        document.querySelector('.header--fixed').classList.remove('active');
      } else {
        document.querySelector('.header--fixed').classList.add('active');
      } 
    }, 300);
  },[])

  const onHover = link => {
    document.querySelector(".overlay--menu").classList.add("hovering")
    document.querySelector('.menu--links .link[data-link="' + link + '"]').classList.add("active")
  }

  const resetMenu = () => {
    document.querySelector(".overlay--menu")?.classList.remove("hovering")
    document.querySelector(".menu--links .link.active")?.classList.remove("active")
  }

  return (
    <>
      <header className="masthead light">
        <div className="p20 flex">
          <div style={{ width: "48px" }} />
          <Link className="mla mra" to="/">
            <div className="logo--main" />
          </Link>
          <div className="journal">
            <p className="menu-button large m0" style={{ width: "48px" }} role="presentation" onClick={menuToggle}>Menu</p>
          </div>
        </div>
      </header>

      <div className='header--fixed'>
        <div className="p1020 flex">

          <div style={{ width: "48px" }}>
            <Link className='link large journal back--button hide' to='/projects'>Back</Link>
          </div>
          <Link className="mla mra" to="/">
            <div className="logo--main alt" />
          </Link>
          <div className="journal">
            <p className="menu-button large m0" style={{ width: "48px" }} role="presentation" onClick={menuToggle}>Menu</p>
          </div>
        </div>
      </div>
 
      <div className="overlay--menu flex">
        <div className="menu--links ma w-100 m-wa p20 journal large flex m-block m-center space-between light m-t40">
          <div className='m-mb20'>
            <Link data-link="projects" onMouseOut={resetMenu} onMouseOver={() => onHover("projects")} className="link ul ul-menu menu-show" activeClassName='current' to="/projects">
              <span>Projects</span>
            </Link>
          </div>
          <div className='m-mb20'>
            <Link data-link="about" onMouseOut={resetMenu} onMouseOver={() => onHover("about")} className="link ul ul-menu menu-show m-mb20" activeClassName='current' to="/about">
              <span>About</span>
            </Link>
          </div>
          <div className='m-mb20'>
            <p data-link="news" onMouseOut={resetMenu} onBlur={resetMenu} onMouseOver={() => onHover("news")} onFocus={() => onHover("news")} onClick={() => openPanel("news")} role="presentation" className="link ul ul-menu m0 menu-show m-inline">
              <span>News</span>
            </p>
          </div>
          <div className='m-mb20'>
            <p data-link="contact" onMouseOut={resetMenu} onBlur={resetMenu} onMouseOver={() => onHover("contact")} onFocus={() => onHover("contact")} onClick={() => openPanel("contact")} role="presentation" className="link ul ul-menu m0 menu-show m-inline">
              <span>Contact</span>
            </p>
          </div>

        </div>
      </div>

      <div className='panel--background' role='presentation' onClick={closePanel} />

    </>
  )
}

export default Header
